import { filter, groupBy } from 'lodash';
import React, { useMemo } from 'react';
import { BATE_RED_HEADING_STYLE } from '../../../common/utils/constants';
import { useAppContext } from '../../../context/AppContext';
import { IHousingUnit } from '../../../types';
import { hasResidenceProxyRoleForUnit } from '../../../utils/roles';
import RoundedBox from '../../Containers/RoundedBox';

interface Props {
  shouldDisplayBoardMessage?: boolean;
  simpleView?: boolean;
  fullWidth?: boolean;
  noFormat?: boolean;
  noHeader?: boolean;
}

interface WrapperProps {
  noFormat?: boolean;
  shouldDisplayBoardMessage?: boolean;
  children: any;
}

const SelectWrapper: React.FC<WrapperProps> = ({ noFormat, shouldDisplayBoardMessage, children }) => {
  if (noFormat) {
    return children;
  }
  return (
    <RoundedBox className={`${shouldDisplayBoardMessage ? '' : 'md:col-start-2 lg:col-start-3 h-fit'}`}>
      {children}
    </RoundedBox>

  );
};

const ResidenceSelector: React.FC<Props> = ({
  shouldDisplayBoardMessage = false, simpleView = false, fullWidth = false, noFormat = false, noHeader = false,
}) => {
  const {
    roles, residenceList, currentResidence, setCurrentResidence,
  } = useAppContext();

  const selectResidence: (residenceId: string) => void = (residenceId) => {
    const residence = residenceList.filter((x) => x.id === residenceId)[0];
    setCurrentResidence(residence);
    localStorage.setItem('selectedResidence', residenceId);
  };

  const isProxyOwnerForCurrentResidence = useMemo(() => {
    return hasResidenceProxyRoleForUnit(roles, currentResidence?.id);
  }, [currentResidence]);

  if (!residenceList || !currentResidence) {
    return null;
  }

  const groupedResidences = filter(groupBy(residenceList, (r) => r.clientName), (g) => g.length > 0);

  return (
    <SelectWrapper shouldDisplayBoardMessage={shouldDisplayBoardMessage} noFormat={noFormat}>
      <div>
        {!noHeader && <h2 className={BATE_RED_HEADING_STYLE}>Min bolig</h2>}
        {residenceList.length > 1 ? (
          <div className={`select-filter ${noHeader ? '' : 'mt-8'} ${fullWidth ? 'select-filter--full-width' : ''}`}>
            <select
              id="residence-filter"
              onChange={(e: any) => selectResidence(e.target.value)}
              value={currentResidence.id}
            >
              {groupedResidences.map((residenceGroup: IHousingUnit[]) => (
                <optgroup key={residenceGroup[0].clientName} label={residenceGroup[0].clientName}>
                  {residenceGroup.map((residence: IHousingUnit) => (
                    <option key={residence.id} value={residence.id}>
                      {residence.address}
                      {' '}
                      {residence.ssbNo}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>
        ) : (
          <div className="mt-8">
            {isProxyOwnerForCurrentResidence && simpleView ? <div>Fullmaktshaver for:</div> : null}
            {simpleView ? currentResidence.address : currentResidence.clientName}
          </div>
        )}
        {!simpleView
        && (
        <div className="mt-8">
          {isProxyOwnerForCurrentResidence ? <p>Fullmaktshaver for:</p> : null}
          <p>
            {currentResidence.address}
          </p>
          <p>
            {currentResidence.zip}
            {' '}
            {currentResidence.city}
          </p>
          {currentResidence.ssbNo ? (
            <p>
              Leilighetsnummer:
              {' '}
              {currentResidence.ssbNo}
            </p>
          ) : null}
          {currentResidence.propertySectionNo ? (
            <p>
              Andelsnummer:
              {' '}
              {currentResidence.propertySectionNo}
            </p>
          ) : null}
          {currentResidence.owners && (
          <div className="pt-8">
            <div className="pb-4 font-clanot-black">Registrerte beboere</div>
            {currentResidence.owners.map((owner, index) => (
              <div key={index} className="flex pb-6">
                <div className="w-8 pr-1">
                  {owner.pictureUrl ? (
                    <img
                      className="h-6 w-6 rounded-full"
                      src={owner.pictureUrl}
                      alt={`${owner.firstName} ${owner.lastName}`}
                    />
                  ) : null}
                </div>
                <div>
                  <div className="text-sm">
                    {owner.firstName}
                    {' '}
                    {owner.lastName}
                  </div>
                  <div className="text-xs">
                    {owner.email}
                  </div>
                </div>
              </div>
            ))}
          </div>
          )}
        </div>
        )}
      </div>
    </SelectWrapper>
  );
};

ResidenceSelector.defaultProps = {
  shouldDisplayBoardMessage: false,
  simpleView: false,
  fullWidth: false,
};

export default ResidenceSelector;
