/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-use-before-define */

import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import {
  get, getBenefitInfo, getRoles, getUnits, getUnreadBulletinCountByUser,
  getUnreadPublicationCount, getUser, getUserCardsAndAccounts, updateUser,
} from '../api';
import { useFetchApiData, useHasMemberRole, useHasResidenceOwnerRole } from '../utils/hooks';
import {
  IUserDetails, IStoreboxApiCardModel, IMemberBenefitsInfo, IHousingUnit, IStoreboxApiCardListModel, IRole,
} from '../types';
import { ActiveSubmittalMeeting, IMeetingQueryModel, PhysicalMeetingModel } from '../types/digitalMeetings';

export const roleMap = {
  owner: 'Eier',
  residenceOwner: 'EierBolig',
  residenceProxy: 'Fullmaktshaver',
  member: 'Medlem',
  boardPortalAdministrator: 'StyreportalAdministrator',
  boardPortalUser: 'StyreportalBruker',
};

interface State {
  roles: IRole[] | null;
  user: IUserDetails | any;
  setUser: React.Dispatch<React.SetStateAction<IUserDetails | null>>,
  userAge: number | null;
  userOfAge: boolean,
  paymentCards: IStoreboxApiCardModel[] | null;
  refetchPaymentCards: (addedAccount?: boolean) => void,
  benefitInfo: IMemberBenefitsInfo | null | undefined;
  residenceList: IHousingUnit[];
  currentResidence: IHousingUnit | null;
  setCurrentResidence: React.Dispatch<React.SetStateAction<IHousingUnit | null>>,
  loadingResidenceList: boolean;
  activeSubmittalMeetings: ActiveSubmittalMeeting[] | null | undefined;
  activeMeetings: IMeetingQueryModel[] | null | undefined;
  physicalMeetings: PhysicalMeetingModel[] | null | undefined;
  refetchActiveMeetings: () => void;
  refetchActiveSubmittalMeetings: () => void;
  loadingPaymentCards: boolean;
  notificationCount: number | undefined;
  updateNotificationCount(): void,
}

const AppContext = createContext<State>({
  roles: null,
  user: null,
  setUser: () => {},
  userAge: null,
  userOfAge: false,
  paymentCards: null,
  refetchPaymentCards: (addedAccount) => {},
  // Set to undefined to indicate value has not yet been set
  benefitInfo: undefined,
  residenceList: [],
  currentResidence: null,
  setCurrentResidence: () => { },
  loadingResidenceList: true,
  activeSubmittalMeetings: [],
  activeMeetings: [],
  physicalMeetings: [],
  loadingPaymentCards: true,
  refetchActiveMeetings: () => { },
  refetchActiveSubmittalMeetings: () => { },
  notificationCount: undefined,
  updateNotificationCount: () => {},
});

export function AppWrapper({ children }: {children: any}): JSX.Element {
  // This is explicitly set to null, so we can distinguish between:
  // - null: Roles have not yet been loaded
  // - []: There are no roles
  // - ['roleKey', ...]: There are one or more roles
  const [roles, setRoles] = useState<IRole[] | null>(null);
  const [user, setUser] = useState<IUserDetails | null>(null);
  const [benefitInfo, setBenefitInfo] = useState<IMemberBenefitsInfo | null | undefined>(undefined);
  const [residenceList, setResidenceList] = useState<IHousingUnit[]>([]);
  const [currentResidence, setCurrentResidence] = useState<IHousingUnit | null>(null);
  const [loadingResidenceList, setLoadingResidenceList] = useState(true);
  const [notificationCount, setNotificationCount] = useState<number>();
  const isAuthenticated = useIsAuthenticated();

  // TODO: Consider throttling this function
  const updateNotificationCount = () => {
    if (!user?.id) return;

    const promises: Promise<number>[] = [];
    promises.push(getUnreadPublicationCount());
    promises.push(getUnreadBulletinCountByUser(user.id));

    Promise.allSettled(promises).then((results) => {
      const publicationCount = results[0].status === 'fulfilled' ? results[0].value : 0;
      const bulletinCount = results[1].status === 'fulfilled' ? results[1].value : 0;
      setNotificationCount(publicationCount + bulletinCount);
    });
  };

  const isMember = useHasMemberRole(roles);

  // :: Fetch payments cards for members.
  const fetchPaymentCards = useMemo(() => {
    if (roles && roles.length > 0) {
      if (isMember) {
        return () => getUserCardsAndAccounts();
      }
    }

    return null;
  }, [roles]);

  // To ensure updated data is fetched after redirect from login,
  // we need to observe the isAuthenticated state
  useEffect(() => {
    async function fetchData() {
      const rolesResponse = await getRoles();
      if (rolesResponse) {
        setRoles(rolesResponse);
      }

      const userResponse = await getUser();
      if (userResponse) {
        setUser(userResponse);
      }

      // getbenefitInfo() crashes with 409 response if member not active,
      // therefore we surround with try and sets null if it failed.
      let benefitInfoResponse : IMemberBenefitsInfo | null | undefined;
      try {
        benefitInfoResponse = await getBenefitInfo();
      } finally {
        if (benefitInfoResponse) {
          setBenefitInfo(benefitInfoResponse);
        } else {
          setBenefitInfo(null);
        }
      }
    }

    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated]);

  const isResidenceOwner = useHasResidenceOwnerRole(roles);

  // :: Fetch residence list for residence owner.
  useEffect(() => {
    async function fetchResidenceList() {
      const units = await getUnits();
      if (units && units.length) {
        setResidenceList(units);

        // Find selected residence id from local storage
        const selectedResidenceId = localStorage.getItem('selectedResidence');

        if (selectedResidenceId) {
          // A residence id exist in local storage
          // -> Find residence
          const residence = units.find((x) => x.id === selectedResidenceId);

          if (residence) {
            // Residence belongs to logged in user.
            setCurrentResidence(residence);
          } else {
            // We did not find a match for the selected residence id from local storage.
            // -> Use first residence for current residence.
            setCurrentResidence(units[0]);
          }
        } else {
          // User has not selected a residence yet.
          // -> Use first residence for current residence.
          setCurrentResidence(units[0]);
        }
      }
    }

    if (roles && roles.length > 0) {
      // Fetch residence list for residence owner
      if (isResidenceOwner) {
      // User is a residence owner and residence list has not been fetched.
        fetchResidenceList();
      }

      setLoadingResidenceList(false);
    }
  }, [roles]); // Fetching residence list is dependent on user roles

  const userAge = useMemo(() => {
    if (!user?.dateOfBirth) return null;
    const dateOfBirth = new Date(user.dateOfBirth);
    const difference = new Date(Date.now() - dateOfBirth.getTime());
    const age = Math.floor(difference.getFullYear() - 1970);
    return age;
  }, [user?.dateOfBirth]);

  // User is considered of age at the age of 15.
  // This is due to laws restricting people under 15 to save bonus.
  const userOfAge = useMemo(() => (userAge ?? 15) >= 15, [userAge]);

  //---------------------------------------------------------------------------
  // Digital meetings
  //---------------------------------------------------------------------------
  // Submittal meetings
  const fetchActiveSubmittalMeetings = useCallback(async () => {
    if (isAuthenticated) {
      const meetings = await get<ActiveSubmittalMeeting[]>('/digitalmeetings/activeSubmittalMeetings');
      return meetings;
    }
    return null;
  }, [isAuthenticated]);

  const { data: activeSubmittalMeetings, refetch: refetchActiveSubmittalMeetings } = useFetchApiData({ fetch: fetchActiveSubmittalMeetings });

  // Active meetings
  const fetchActiveMeetings = useCallback(async () => {
    if (isAuthenticated) {
      const meetings = await get<IMeetingQueryModel[]>('/digitalmeetings/active');
      return meetings;
    }
    return [];
  }, [isAuthenticated]);

  const { data: activeMeetings, refetch: refetchActiveMeetings } = useFetchApiData({ fetch: fetchActiveMeetings });

  // Active meetings
  const fetchPhysicalMeetings = useCallback(async () => {
    if (isAuthenticated) {
      const meetings = await get<PhysicalMeetingModel[]>('/digitalmeetings/physical');
      return meetings;
    }
    return [];
  }, [isAuthenticated]);

  const { data: physicalMeetings } = useFetchApiData({ fetch: fetchPhysicalMeetings });
  // ---------------------------------------------------------------------------

  const {
    data: paymentCards,
    loading: loadingPaymentCards,
    refetch: _refetchPaymentCards,
  } = useFetchApiData<IStoreboxApiCardListModel>({ fetch: fetchPaymentCards });

  const refetchPaymentCards = useCallback(async (addedAccount = false) => {
    const previousCards = paymentCards;
    const newCards = await _refetchPaymentCards();

    if (addedAccount && newCards && !user?.memberCashbackAccount) {
      // Filter away existing cards and non account cards
      const addedCards = newCards.data.filter(
        (card) => !previousCards?.data.some(
          (pc) => card.cardNumber === pc.cardNumber,
        ),
      ).filter((card) => card.cardTypeId === 16);

      if (addedCards.length === 0) return;

      // Set new account as member cashback account
      const newAccount = addedCards[0];
      updateUser({ ...user, memberCashbackAccount: newAccount.cardNumber })
        .catch(() => console.log('Error saving data'));
      setUser({ ...user, memberCashbackAccount: newAccount.cardNumber });
    }
  }, [_refetchPaymentCards, paymentCards, user]);

  const state = useMemo(() => {
    const s: State = {
      roles,
      user,
      setUser,
      userAge,
      userOfAge,
      paymentCards: [],
      refetchPaymentCards,
      benefitInfo,
      residenceList,
      loadingResidenceList,
      loadingPaymentCards: true,
      currentResidence,
      setCurrentResidence,
      notificationCount,
      updateNotificationCount,
      activeMeetings,
      activeSubmittalMeetings,
      physicalMeetings,
      refetchActiveMeetings,
      refetchActiveSubmittalMeetings,
    };

    if (paymentCards?.data) {
      s.paymentCards = paymentCards.data;
    }

    return s;
  }, [roles, user, paymentCards, benefitInfo, residenceList, currentResidence, notificationCount]);

  // Update notification count when user data is available
  useEffect(() => {
    updateNotificationCount();
  }, [user]);

  state.loadingPaymentCards = loadingPaymentCards;

  return (
    <AppContext.Provider value={state}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
