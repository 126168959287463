/* eslint-disable no-console */
import React, { useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import {
  BrowserRouter as Router, Switch,
} from 'react-router-dom';
import Modal from 'react-modal';
import routes from './config/routes';
import AppRoute, { AppRouteProps } from './components/AppRoute';
import Header from './components/Header';
import Footer from './components/Modules/Footer';
import './styles/globals.scss';
import { LoadingProvider } from './context/LoadingContext';

const App: React.FC = () => {
  const [navigationMenuOpen, setNavigationMenuOpen] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const [pageBackground, setPageBackground] = useState('bg-white');

  Modal.setAppElement('#root');

  return (
    <Router getUserConfirmation={() => {
      /* Empty callback to block the default browser prompt */
    }}
    >
      <LoadingProvider>

        <div id="root" className={`flex flex-col min-h-screen ${pageBackground}`}>
          {isAuthenticated
            && (
              <Header
                navigationMenuOpen={navigationMenuOpen}
                setNavigationMenuOpen={setNavigationMenuOpen}
                setPageBackground={setPageBackground}
              />
            )}
          <div className={`${navigationMenuOpen ? 'hidden' : ''} flex-1 flex flex-col`}>
            <Switch>
              {routes.map((route: AppRouteProps) => (
                <AppRoute
                  key={route.path}
                  component={route.component}
                  path={route.path}
                  isPrivate={route.isPrivate}
                  section={route.section}
                />
              ))}
            </Switch>

          </div>
          {isAuthenticated && (
            <div className="mt-auto pt-12">
              <Footer />
            </div>
          )}
        </div>
      </LoadingProvider>
    </Router>
  );
};

export default App;
