/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useMemo, useReducer, useState,
} from 'react';
import InternalLink from '../../../common/components/link/InternalLink';
import { InternalLinkType } from '../../../common/utils/enums';
import CloseButton from '../../../components/Elements/CloseButton';
import {
  ActiveSubmittalMeeting,
  MeetingStateEnum,
  IMeetingQueryModel,
  PhysicalMeetingModel,
  CompanyTypeEnum,
} from '../../../types/digitalMeetings';
import { formatDate, timeUntil } from '../../../utils/date';
import { useInterval } from '../../../utils/hooks';
import { useAppContext } from '../../../context/AppContext';

export enum MeetingType{
    Submittal = 'Submittal',
    Active = 'Active',
    Physical = 'Physical',
}

type Props = {
  meeting: ActiveSubmittalMeeting | IMeetingQueryModel | PhysicalMeetingModel,
  userMeetings? : IMeetingQueryModel[],
  meetingType: MeetingType,
  hideable?: boolean,
}

export const DigitalMeetingsBanner: React.FC<Props> = ({
  meeting: selectedMeeting,
  userMeetings = [],
  meetingType,
  hideable,
}) => {
  const [visible, setVisible] = useState(true);
  const [counter, increment] = useReducer((x: number) => x + 1, 0);

  useInterval(increment, 1000 * 10);

  const { user } = useAppContext();

  const meetingInfo = useMemo(() => {
    // If there is an ongoing voitng show the voting
    if (meetingType === MeetingType.Active) {
      const meeting = selectedMeeting as IMeetingQueryModel;
      const meetings = userMeetings;
      const meetingName = meeting.title;
      const { clientName } = meeting;
      const votingStart = formatDate(meeting.start!, true);
      const votingEnd = formatDate(meeting.deadline!, true);

      const propertyId = meeting.propertyIds[0];

      const numberOfVotes = (meeting.companyType === CompanyTypeEnum.Cooperative)
        ? (userMeetings.reduce((prevValue, currentValue) => prevValue + currentValue.propertyIds.length, 0) ?? 0)
        : (userMeetings.length ?? 0);

      const proxy = '';

      // Meeting starts in preview phase
      if (meeting.meetingState === MeetingStateEnum.Preview) {
        const { days, hours, minutes } = timeUntil(meeting.commentsStart!);

        return {
          title: `${meetingName} i ${clientName}`,
          text: `Nå kan du se sakene som er innmeldt. Du kan legge inn kommentarer når møtet starter 
          ${formatDate(meeting.commentsStart, true)}. 
          Inntil videre kan du gjøre deg kjent med sakene som skal behandles i møtet.`,
          secondaryText: '',
          linkText: 'Se saker',
          linkUrl: `/bolig/arsmote/${meeting.id}/${propertyId}`,
          useCountdown: true,
          countDownTitle: 'Møtet starter om:',
          countdownDays: days,
          countdownHours: hours,
          countdownMinutes: minutes,
          phase: '',
          proxy,
          meetings,
          numberOfVotes,
        };
      }

      // After the preview phase users will be able to comment on the submittals
      if (meeting.meetingState === MeetingStateEnum.Comments) {
        const { days, hours, minutes } = timeUntil(meeting.start!);
        return {
          title: `${meetingName} i ${clientName}`,
          text: `Årsmøtet pågår digitalt i perioden fra 
          ${votingStart} til ${votingEnd}.`,
          secondaryText: `Frem til stemmegivningen starter kan du gjøre deg kjent med sakene 
                          som skal behandles og skrive kommentarer. Du vil motta eget varsel
                           når stemmegivningen starter.`,
          linkText: 'Se sakene',
          linkUrl: `/bolig/arsmote/${meeting.id}/${propertyId}`,
          useCountdown: true,
          countDownTitle: 'Møtet starter om:',
          countdownDays: days,
          countdownHours: hours,
          countdownMinutes: minutes,
          phase: 'Nå kan du kommentere!',
          proxy,
          meetings,
          numberOfVotes,
        };
      }

      // After the commenting phase user need to wait for voting
      if (meeting.meetingState === MeetingStateEnum.None) {
        const { days, hours, minutes } = timeUntil(meeting.start!);

        return {
          title: `${meetingName} i ${clientName}`,
          text: 'Møtet starter straks. ',
          secondaryText: 'Frem til avstemningen starter kan du gjøre deg kjent med sakene som skal stemmes over.',
          linkText: 'Se saker',
          linkUrl: `/bolig/arsmote/${meeting.id}/${propertyId}`,
          useCountdown: true,
          countDownTitle: 'Avstemming starter om:',
          countdownDays: days,
          countdownHours: hours,
          countdownMinutes: minutes,
          phase: 'Pause',
          proxy,
          meetings,
          numberOfVotes,
        };
      }

      // Voting phase
      if (meeting.meetingState === MeetingStateEnum.Voting) {
        return {
          title: `${meetingName} i ${clientName}`,
          text: `Årsmøtet i ${clientName} er i gang, og du kan avgi stemme frem til ${votingEnd}.`,
          secondaryText: '',
          linkText: 'Delta',
          linkUrl: `/bolig/arsmote/${meeting.id}/${propertyId}`,
          useCountdown: false,
          countDownTitle: '',
          countdownDays: 0,
          countdownHours: 0,
          countdownMinutes: 0,
          phase: 'Nå kan du stemme!',
          proxy,
          meetings,
          numberOfVotes,
        };
      }
    }

    // If there is an upcoming meeting show the upcoming meeting info and to allow for cases.
    if (meetingType === MeetingType.Submittal) {
      const nextMeeting = selectedMeeting as ActiveSubmittalMeeting;
      const meetingStart = nextMeeting.physicalMeetingStart && formatDate(nextMeeting.physicalMeetingStart);
      const sectionSubmittalDeadline = nextMeeting.sectionSubmittalDeadline
        && formatDate(nextMeeting.sectionSubmittalDeadline);
      const { clientName } = nextMeeting;
      const meetingName = nextMeeting.title.toLowerCase();
      return {
        title: `Meld sak til ${meetingName} i ${clientName}`,
        text: `Nå kan du melde sak til ${meetingName} i ${clientName} som avholdes 
                ${meetingStart}. Frist for innmelding av saker er ${sectionSubmittalDeadline}.`,
        secondaryText: '',
        linkText: 'Meld sak',
        linkUrl: `/bolig/arsmote/${nextMeeting.id}`,
        useCountdown: false,
        phase: 'Innmeldingsfase',
        proxy: '',
      };
    }

    if (meetingType === MeetingType.Physical) {
      const meeting = selectedMeeting as PhysicalMeetingModel;
      const { days, hours, minutes } = timeUntil(meeting.meetingDate);

      return {
        title: `${meeting.meetingTitle} i ${meeting.clientName}`,
        text: `${meeting.meetingTitle} avholdes ${formatDate(meeting.meetingDate, true)}. 
        Frem til møtet avholdes kan du gjøre deg kjent med sakene som skal behandles.`,
        secondaryText: null,
        linkText: 'Se innkalling',
        linkUrl: `/bolig/arsmote/${meeting.id}`,
        location: meeting.location,
        useCountdown: true,
        countDownTitle: 'Møtet starter om:',
        countdownDays: days,
        countdownHours: hours,
        countdownMinutes: minutes,
        phase: null,
        proxy: meeting.isProxyOwner && !meeting.isOwner ? `Fullmaktshaver for ${meeting.proxyOwnerName}` : '',
      };
    }

    return null;
  }, [meetingType, selectedMeeting, userMeetings, counter]);

  if (!meetingInfo || !visible) {
    return null;
  }

  return (
    <div className="flex">
      <div className="bg-white rounded-bate mt-5 relative w-full shadow-lg px-7 py-8 lg:p-10">
        { hideable && (
        <div className="absolute top-2 right-2 p-3">
          <CloseButton onClick={() => { setVisible(false); }} iconColor="text-bate-red" />
        </div>
        )}

        <div className="md:text-xl text-dark">
          <div className="pb-6 md:pb-12 border-b-2 border-medium-light-grey">
            {/* Heading */}
            <div className="text-3xl md:text-4.5xl font-clanot-ultra text-bate-red heading-underline pr-6">
              {meetingInfo.title}
            </div>
            <p className="mt-5">{meetingInfo.phase}</p>

            {meetingInfo.text && (
              <p className="mt-5">
                {meetingInfo.text}
              </p>
            )}

            {meetingInfo.secondaryText && (
              <p className="mt-5">
                {meetingInfo.secondaryText}
              </p>
            )}

            { meetingInfo.numberOfVotes && (
              <p className="mt-5 text-dark">
                Du har totalt
                {' '}
                <span className="font-bold">{ meetingInfo.numberOfVotes}</span>
                {' '}
                { meetingInfo.numberOfVotes === 1 ? 'stemme' : 'stemmer'}
              </p>
            )}

            {meetingInfo.location && (
              <p className="mt-5 text-bate-red">
                Sted:
                {' '}
                {meetingInfo.location}
              </p>
            )}
          </div>

          {/* Countdown */}
          {meetingInfo.useCountdown && (
            <div className="py-6 md:py-12 border-b-2 border-medium-light-grey">
              <div className="font-clanot-black mb-2">
                {meetingInfo.countDownTitle}
              </div>

              <div className="flex flex-wrap">
                <div className="mr-5 md:mr-10">
                  <span className="font-clanot-ultra text-3xl md:text-4.5xl text-bate-red mr-2">
                    {meetingInfo.countdownDays}
                  </span>
                  <span>dager</span>
                </div>
                <div className="mr-5 md:mr-10">
                  <span className="font-clanot-ultra text-3xl md:text-4.5xl text-bate-red mr-2">
                    {meetingInfo.countdownHours}
                  </span>
                  <span>timer</span>
                </div>
                <div className="mr-5 md:mr-10">
                  <span className="font-clanot-ultra text-3xl md:text-4.5xl text-bate-red mr-2">
                    {meetingInfo.countdownMinutes}
                  </span>
                  <span>minutter</span>
                </div>
              </div>
            </div>
          )}

          {meetingInfo.proxy && (
            <div className="mt-5 text-dark">
              {meetingInfo.proxy}
            </div>
          )}

          { meetingType === MeetingType.Active
            && (meetingInfo.meetings && meetingInfo.meetings[0].meetingState === MeetingStateEnum.Voting)
            ? (
              <div className="mt-8 md:mt-10">
                { meetingInfo.meetings[0].companyType === CompanyTypeEnum.Cooperative && (
                  !!meetingInfo.meetings && meetingInfo.meetings.map((m) => m.propertyIds.map((p) => {
                    const linkUrl = `/bolig/arsmote/${m.id}/${p}`;

                    return (
                      <div key={`cooperative-property-id-${p}`} className="flex flex-col items-start mb-8">
                        <p className="mb-2">
                          { m.isProxyMeeting ? `Fullmaktshaver for ${m.proxyOwner}` : user.firstname }
                        </p>
                        <InternalLink
                          link={{ link: { href: linkUrl }, text: meetingInfo.linkText, type: InternalLinkType.Minside }}
                          shouldDisplayButton
                          inline={false}
                        />
                      </div>
                    );
                  }))
                )}

                { meetingInfo.meetings[0].companyType !== CompanyTypeEnum.Cooperative && (

                  !!meetingInfo.meetings && meetingInfo.meetings.map((m) => {
                    const linkUrl = `/bolig/arsmote/${m.id}/${m.propertyIds[0]}`;

                    return (
                      <div key={`all-property-id-${m.propertyIds[0]}`} className="flex flex-col items-start mb-8">
                        <p className="mb-2">
                          { m.isProxyMeeting ? `Fullmaktshaver for ${m.proxyOwner}` : user.firstname }
                        </p>
                        <InternalLink
                          link={{ link: { href: linkUrl }, text: meetingInfo.linkText, type: InternalLinkType.Minside }}
                          shouldDisplayButton
                          inline={false}
                        />
                      </div>
                    );
                  })
                )}
              </div>
            )
            : (
              <InternalLink
                link={{
                  link: { href: meetingInfo.linkUrl }, text: meetingInfo.linkText, type: InternalLinkType.Minside,
                }}
                className="mt-8 md:mt-10"
                shouldDisplayButton
                inline={false}
              />
            )}
        </div>

      </div>
    </div>
  );
};
